<template>
  <div>
    <a-row :gutter="16">
      <a-col :lg="24" :xl="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-1">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :lg="24" :xl="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="menuActive === 1">
              <kbm />
            </template>
            <template v-if="menuActive === 2">
              <report-schedule />
            </template>
            <template v-if="menuActive === 3">
              <student-parent-progress />
            </template>
            <template v-if="menuActive === 4">
              <subject-teachers-progress/>
            </template>
            <template v-if="menuActive === 5">
              <homeroom-teacher-progress />
            </template>
            <template v-if="menuActive === 6">
              <teacher-planning-progress />
            </template>
            <template v-if="menuActive === 7">
              <daily-attendance />
            </template>
            <template v-if="menuActive === 8">
              <graduation-letter />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Menu from '@/components/app/MenuV2'
const KBM = () => import('@/views/Teacher/Curriculum/KBM')
const ReportSchedule = () => import('@/views/Teacher/Curriculum/ReportSchedule')
const StudentParentProgress = () => import('@/views/Teacher/Curriculum/StudentParentProgress')
const HomeroomTeacherProgress = () => import('@/views/Teacher/Curriculum/HomeroomTeacherProgress')
const SubjectTeachersProgress = () => import('@/views/Teacher/Curriculum/SubjectTeachersProgress')
const TeacherPlanningProgress = () => import('@/views/Teacher/Curriculum/TeachersPlanningProgress')
const GraduationLetter = () => import('@/views/Teacher/Curriculum/GraduationLetter')
const DailyAttendance = () => import('@/views/Teacher/Curriculum/DailyAttendance')

const menus = [
  {
    key: 1,
    menu: 'KBM',
  },
  {
    key: 2,
    menu: 'Report Schedule',
  },
  {
    key: 3,
    menu: 'Student Parent Data Progress',
  },
  {
    key: 4,
    menu: 'Subject Teachers Progress',
  },
  {
    key: 5,
    menu: 'Homeroom Teachers Progress',
  },
  {
    key: 6,
    menu: 'Teacher RPP Progress',
  },
  {
    key: 7,
    menu: 'Daily Attendance',
  },
  {
    key: 8,
    menu: 'Graduation Letter',
  },
]

export default {
  components: {
    Menu,
    kbm: KBM,
    ReportSchedule,
    StudentParentProgress,
    HomeroomTeacherProgress,
    SubjectTeachersProgress,
    TeacherPlanningProgress,
    GraduationLetter,
    DailyAttendance,
  },
  data() {
    return {
      menus,
      menuActive: 1,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
  mounted() {
  },
}
</script>
<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}
.detail {
  padding: 20px;
}
.editable-row-actions {
  margin-right: 8px;
}
.ant-progress-circle-wrap,
.ant-progress-line-wrap {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
