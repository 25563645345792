<template>
  <div>
    <!-- <h1>Curriculum - KBM</h1> -->
    <a-row :gutter="16">
      <a-col :lg="24" :xl="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-1">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :lg="24" :xl="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <template v-if="menuActive === 4">
              <subject-teachers-progress/>
            </template> -->
            <template v-if="menuActive === 1">
              <homeroom-teacher-progress />
            </template>
            <template v-else-if="menuActive === 2">
              <teacher-planning-progress />
            </template>
            <template v-else-if="menuActive === 3">
              <daily-attendance />
            </template>
          </div>
        </div>
      </a-col>
      <!-- <a-col :span="24">
        <div class="card-full">
          <div class="detail">
              <div class="d-flex flex-column flex-md-row align-items-center my-4">
                  <div class="w-100 w-lg-75">
                    <a-divider orientation="left">
                        <h3>{{this.activeYear.semester}} - {{this.activeYear.tahun}}</h3>
                    </a-divider>
                  </div>
                  <div class="w-auto d-flex flex-column flex-md-row ml-3">
                      <template v-if="!editKBM">
                        <a-button @click.prevent="toEditKBM" shape="round" type="primary" icon="edit">Edit KBM</a-button>
                      </template>
                      <template v-else>
                        <a-button
                        @click.prevent="cancelEdit"
                        :loading="loadingCancel"
                        type="danger"
                        shape="round"
                        icon="close"
                        class="mb-3 mb-lg-0 mr-0 mr-md-3"
                        >CANCEL</a-button>
                        <a-button
                        @click.prevent="save"
                        shape="round"
                        icon="save"
                        style="background-color: #41B883; color: white;"
                        >SAVE</a-button>
                      </template>
                  </div>
            </div>
            <a-row type="flex" align="middle" justify="center">
                <a-col :span="24">
                    <a-table :columns="columnCurriculums" :data-source="dataTable" bordered :pagination="{ pageSize: 30, hideOnSinglePage: true }">
                        <div v-if="editKBM" slot="KBM" slot-scope="text, record">
                            <a-input
                            :value="text"
                            style="height: 48px; width:80px;"
                            @change="e => handleChange(e.target.value, record.key, 'KBM')"
                            />
                        </div>
                        <div v-else slot="KBM" slot-scope="text">
                            <span>{{text}}</span>
                        </div>
                    </a-table>
                </a-col>
            </a-row>
          </div>
        </div>
      </a-col> -->
    </a-row>
  </div>
</template>

<script>
import Menu from '@/components/app/MenuV2'
import moment from 'moment'
const HomeroomTeacherProgress = () => import('@/views/Teacher/Curriculum/HomeroomTeacherProgress')
const TeacherPlanningProgress = () => import('@/views/Teacher/Curriculum/TeachersPlanningProgress')
const DailyAttendance = () => import('@/views/Teacher/Curriculum/DailyAttendance')

// const SubjectTeachersProgress = () => import('@/views/Teacher/Curriculum/SubjectTeachersProgress')

const menus = [
  // {
  //   key: 4,
  //   menu: 'Subject Teachers Progress',
  // },
  {
    key: 1,
    menu: 'Homeroom Teachers Progress',
  },
  {
    key: 2,
    menu: 'Teacher RPP Progress',
  },
  {
    key: 3,
    menu: 'Daily Attendance',
  },
]

export default {
  components: {
    Menu,
    HomeroomTeacherProgress,
    TeacherPlanningProgress,
    DailyAttendance,
  },
  data() {
    return {
      menus,
      menuActive: 1,
      modalVisible: false,
      modalReportSubmissionVisible: false,
      confirmLoading: false,
      loadingCancel: false,
      loadingTable: false,
      loadingTableReportSubmission: false,
      editKBM: false,
      loadingAddReportSubmission: false,
      savedData: [],
      dataTable: [],
      dataReportSubmission: [],
      tahunAjarans: [],
      newReportSubmission: {
        id_tahun_ajaran: '',
        tipe: 'mid',
        tanggal_awal_submisi: null,
        tanggal_akhir_submisi: null,
        tanggal_publikasi: null,
      },
      activeYear: '',
      startValue: null,
      endValue: null,
      endOpen: false,
      selectedClassId: '',
      cascaderClassText: 'All Class',
      loadingDelete: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      idEdit: null,
      classOptions: [],
      dataSPallClass: [],
      emailCP: 0,
      phoneCP: 0,
      whatsappCP: 0,
    }
  },
  methods: {
    deleteReportSchedule(data) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete report schedule for <b>{data.type.toLowerCase()}</b> report on <b>{data.tahunAjaran}</b> ? your data will be lost</div>
        ),
        onOk: () => {
          this.$store.dispatch('curriculum/DELETE_REPORT_SETTING', {
            idReportSetting: data.key,
          })
            .then(isDeleted => {
              this.loadingDelete = false
              if (isDeleted) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Report schedule has been removed',
                })
                this.fetchDataReportSchedule()
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'Report schedule cannot be deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    disabledBeforeNow(value) {
      return value.valueOf() < moment().add(-1, 'day').valueOf()
    },
    fetchDataClass() {
      this.$store.dispatch('curriculum/FETCH_CLASS')
        .then(res => {
          // console.log('res', res)
          const classes = res.classes
          const levels = res.levels
          const optionsData = levels.map(row => {
            const children = []
            classes.forEach(el => {
              const classLevel = el.tingkat
              const classId = el.id
              const classSymbol = el.simbol
              const childObj = {
                label: classSymbol,
                value: classId,
              }
              if (row.tingkat === classLevel) {
                children.push(childObj)
              }
            })
            return {
              value: row.tingkat,
              label: row.tingkat,
              children,
            }
          })
          const optionsObj = {
            label: 'All',
            value: 'All',
            children: [{
              label: 'Class',
              value: '',
            }],
          }
          optionsData.push(optionsObj)
          // console.log('optionsData', optionsData)
          this.classOptions = optionsData
        })
    },
    onChangeClass(value, selectedClassOptions) {
      // console.log('value', value)
      this.selectedClassId = value[1]
      this.cascaderClassText = selectedClassOptions.map(o => o.label).join(' - ')
      this.fetchDataSPprogress()
    },
    fetchDataSPprogress() {
      this.loadingTable = true
      this.$store.dispatch('curriculum/FETCH_STUDENT_PARENT_INPUT_DATA_PROGRESS', { idKelas: this.selectedClassId })
        .then(res => {
          // console.log('res fetchDataSPprogress', res)
          const message = res.message
          const data = res.data
          const percentage = res.percentage
          if (message === 'SP_BY_CLASS_ID') {
            let no = 1
            this.dataTable = data.map(row => {
              // console.log('row.emailStatus', row.emailStatus)
              return {
                key: row.id,
                No: no++,
                Name: row.nama,
                Email: row.emailStatus,
                Phone: row.phoneStatus,
                Whatsapp: row.whatsappStatus,
              }
            })
            // this.savedData = this.dataTable
            this.loadingTable = false
            this.emailCP = percentage.emailCP
            this.phoneCP = percentage.phoneCP
            this.whatsappCP = percentage.whatsappCP
          } else {
            // console.log('data', data)
            this.dataSPallClass = data
          }
        })
    },
    handleChangeTahunAjaran(value) {
      this.newReportSubmission.id_tahun_ajaran = value
    },
    handleChangeType(e) {
      this.newReportSubmission.tipe = e.target.value
    },
    disabledStartDate(startValue) {
      const endValue = this.newReportSubmission.tanggal_akhir_submisi
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.newReportSubmission.tanggal_awal_submisi
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleModalAddReportSubmission(status, data) {
      this.modalReportSubmissionVisible = !this.modalReportSubmissionVisible
      if (!this.modalReportSubmissionVisible) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newReportSubmission = {
            id_tahun_ajaran: null,
            tipe: 'mid',
            tanggal_awal_submisi: null,
            tanggal_akhir_submisi: null,
            tanggal_publikasi: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }
      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          console.log(data)
          this.idEdit = data.key
          this.newReportSubmission = {
            id_tahun_ajaran: data.idTahunAjaran,
            tipe: data.type.toLowerCase(),
            tanggal_awal_submisi: data.startAt ? moment(data.startAt, 'DD MMMM YYYY HH:mm') : null,
            tanggal_akhir_submisi: data.endAt ? moment(data.endAt, 'DD MMMM YYYY HH:mm') : null,
            tanggal_publikasi: data.publication ? moment(data.publication, 'DD MMMM YYYY HH:mm') : null,
          }
        }
      }
    },
    reportScheduleIsExist() {
      this.fetchDataReportSchedule()
      const isExist = Boolean(this.dataReportSubmission.find(report =>
        report.idTahunAjaran === this.newReportSubmission.id_tahun_ajaran && report.type.toLowerCase() === this.newReportSubmission.tipe.toLowerCase(),
      ))
      return isExist
    },
    handleOkAddReportSubmission() {
      // console.log(this.reportScheduleIsExist())
      if (this.reportScheduleIsExist() && this.status.isCreate) {
        return this.$notification.error({
          message: 'Error.',
          description: 'Report Schedule you tried to create is already exist. Use edit to change it.',
        })
      }
      this.loadingAddReportSubmission = true
      const payload = this.status.isEdit ? { id: this.idEdit, data: this.newReportSubmission } : this.newReportSubmission
      this.$store.dispatch(`curriculum/${this.status.isEdit ? 'EDIT' : 'POST'}_REPORT_SUBMISSION`, payload)
        .then(isSuccess => {
          this.loadingAddReportSubmission = false
          this.toggleModalAddReportSubmission()
          if (isSuccess) {
            const description = this.status.isEdit ? 'Report Schedule has been edited' : 'New Report Schedule has been created'
            this.$notification.success({
              message: 'Success.',
              description,
            })
            this.fetchDataReportSchedule()
          } else {
            const description = this.status.isEdit ? 'Report Schedule has not been edited' : 'New Report Schedule has not been created'
            this.$notification.error({
              message: 'Error.',
              description,
            })
          }
        })
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    toEditKBM () {
      this.editKBM = true
    },
    save() {
      this.modalVisible = true
    },
    handleChange(value, key, column = 'KBM') {
      // console.log('this.selectView', this.selectView)
      const newData = [...this.dataTable]
      const target = newData.filter(item => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
      // console.log('this.savedData', this.savedData)
      // console.log('target', target)
    },
    cancelEdit() {
      this.loadingCancel = true
      this.fetchDataCurriculum()
    },
    fetchDataCurriculum () {
      this.loadingTable = true
      this.$store.dispatch('curriculum/FETCH_CURRICULUM_ALL_MAPEL_KBM')
        .then(res => {
          const data = res.data
          this.loadingCancel = false
          this.editKBM = false
          this.loadingTable = false
          let no = 1
          this.dataTable = data.map(row => {
            return {
              key: row.id,
              No: no++,
              Subjects: row.nama,
              Code: row.code,
              KBM: row.kbm,
            }
          })
          this.activeYear = res.activeYear
          this.savedData = this.dataTable
        })
    },
    async fetchDataReportSchedule() {
      this.loadingTableReportSubmission = true
      const res = await this.$store.dispatch('report/FETCH_ALL_REPORT_SUBMISSION')
      const data = res.reportSetting
      this.loadingTableReportSubmission = false
      this.dataReportSubmission = data.map(row => {
        // console.log(moment(row.tanggal_akhir_submisi).format('DD MMMM YYYY HH:mm'))
        return {
          key: row.id,
          tahunAjaran: `${row.tahun_ajaran.tahun} (${row.tahun_ajaran.semester.toUpperCase()})`,
          idTahunAjaran: row.tahun_ajaran.id,
          type: row.tipe.toUpperCase(),
          startAt: row.tanggal_awal_submisi ? moment(row.tanggal_awal_submisi).format('DD MMMM YYYY HH:mm') : null,
          endAt: row.tanggal_akhir_submisi ? moment(row.tanggal_akhir_submisi).format('DD MMMM YYYY HH:mm') : null,
          publication: row.tanggal_publikasi ? moment(row.tanggal_publikasi).format('DD MMMM YYYY HH:mm') : null,
        }
      })

      // return new Promise((resolve) => { resolve(true) })
    },
    fetchDataTahunAjaran() {
      this.$store.dispatch('report/FETCH_ALL_TAHUN_AJARAN')
        .then(res => {
          this.tahunAjarans = res
        })
    },
    validateDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // console.log(data[i])
        if (data[i].KBM === '-') {
          data[i].KBM = null
        }
        if (isNaN(Number(data[i].KBM))) {
          isInvalid = true
          message = `Sorry, ${data[i].Subjects}'s KBM must be numbers. ${i}`
          break
        } else if (Number(data[i].KBM) > 100 || Number(data[i].KBM) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].Subjects}'s KBM must be between 0 and 100.`
          break
        }
      }

      return {
        isInvalid,
        message,
      }
    },
    handleOk() {
    //   console.log(this.savedData)
      const newData = [...this.savedData]
      if (newData.length) {
        const validation = this.validateDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.modalVisible = false
        } else {
          const target = newData.map(row => {
            return {
              id: row.key,
              kbm: Number(row.KBM),
            }
          })
          //   console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store.dispatch('curriculum/UPDATE_KBM', {
            data: target,
          })
            .then(res => {
              setTimeout(() => {
                this.fetchDataCurriculum()
                this.editKBM = false
                this.modalVisible = false
                this.confirmLoading = false
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Success',
                    description: 'KBM has been updated',
                  })
                } else {
                  this.$notification.error({
                    message: 'Sorry',
                    description: 'KBM has not been updated',
                  })
                }
              }, 1000)
            })
        }
      } else {
        this.editKBM = false
        this.confirmLoading = true
        setTimeout(() => {
          this.$notification.error({
            message: 'Sorry',
            description: 'KBM has not been updated',
          })
          this.modalVisible = false
          this.confirmLoading = false
        }, 1000)
      }
    },
    handleCancel() {
      this.modalVisible = false
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
  created () {
  },
}
</script>
<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}
.detail {
  padding: 20px;
}
.editable-row-actions {
  margin-right: 8px;
}
.ant-progress-circle-wrap,
.ant-progress-line-wrap {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
