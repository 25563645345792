<template>
  <div>
    <LoadingState v-if="isLoading" />
    <main-card v-else>
      <span slot="title1">Homeroom Reports</span>
      <span slot="title2">{{ header.kelas }}</span>
      <span slot="sub-title1">{{ header.totalMurid }} Students</span>
      <span slot="sub-title2"
        >{{ header.tahunAjar }} ({{ header.semester }})</span
      >
      <div slot="content">
        <a-row :gutter="16">
          <a-col
            :lg="8"
            :md="24"
            v-sticky="{ zIndex: 99, stickyTop: 10, disabled: isDisabledSticky }"
          >
            <div class="border rounded">
              <div class="card-header card-header-flex">
                <div
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <div>
                    <h2 class="font-size-18 text-dark">List of Students</h2>
                  </div>
                </div>
              </div>
              <div class="card-body scrolling-card">
                <template v-if="loadingListPersonalData">
                  <a-skeleton
                    v-for="item in 5"
                    :key="item"
                    active
                    :paragraph="{ rows: 2 }"
                  />
                </template>
                <Menu
                  v-else
                  :menus="menus"
                  :menuActive="menuActive"
                  @menu-change="menuChange"
                />
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <a class="text-primary" @click.prevent="backToMenu"
                      >Back to Menu</a
                    >
                  </div>
                  <div>
                    <kbd class="bg-primary">↑</kbd>
                    <kbd class="bg-primary mx-2">↓</kbd>
                    <span>To Navigate</span>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :lg="16" :md="24">
            <div class="border rounded p-4">
              <template v-if="loadingDetailPersonalData || !menus.length">
                <a-skeleton
                  v-for="item in 5"
                  :key="item"
                  active
                  :paragraph="{ rows: 2 }"
                />
              </template>
              <template v-else>
                <div class="text-center">
                  <a
                    style="letter-spacing: 1px"
                    class="btn btn-success"
                    @click.prevent="verifyNow"
                    v-if="isPrincipal && dataReport.key"
                    :disabled="dataReport.is_verify_kepala_sekolah"
                  >
                    <a-icon :type="'check-square'" />
                    {{
                      dataReport.is_verify_kepala_sekolah
                        ? 'VERIFIED'
                        : 'VERIFY NOW'
                    }}</a
                  >
                </div>
                <template v-if="menuActive === dataReport.key">
                  <template v-if="dataReport.version === null">
                    <report-mid-view
                      v-if="type === 'mid'"
                      :configSchool="config.school"
                      :generalReport="dataReport"
                    />
                    <report-final-view
                      v-else-if="type === 'final'"
                      :configSchool="config.school"
                      :generalReport="dataReport"
                      page
                    />
                  </template>
                  <template v-if="dataReport.version === 1">
                    <report-mid-view-v2
                      v-if="type === 'mid'"
                      :configSchool="config.school"
                      :dataReport="dataReport"
                      :kumer="kumer"
                    />
                    <report-final-view-v2
                      v-else-if="type === 'final'"
                      :configSchool="config.school"
                      :dataReport="dataReport"
                      page
                      :kumer="kumer"
                    />
                  </template>
                </template>
                <EmptyState
                  class="my-5"
                  v-else
                  heading="No Data"
                  description="This student still has no data report."
                />
              </template>
            </div>
          </a-col>
        </a-row>
      </div>
    </main-card>
  </div>
</template>

<script>
import config from '@/config'
import VueSticky from 'vue-sticky'
const mainCard = () => import('@/components/app/shared/mainCard')
const LoadingState = () => import('@/components/app/LoadingState')
const Menu = () => import('@/components/app/MenuV2')
const ReportMidView = () => import('@/components/app/Report/Mid')
const ReportFinalView = () => import('@/components/app/Report/Final')
const ReportMidViewV2 = () => import('@/components/app/Report/Mid/v2')
const ReportFinalViewV2 = () => import('@/components/app/Report/Final/v2')
const EmptyState = () => import('@/components/app/EmptyState')

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    mainCard,
    LoadingState,
    Menu,
    ReportMidView,
    ReportFinalView,
    ReportMidViewV2,
    ReportFinalViewV2,
    EmptyState,
  },
  data() {
    return {
      config,
      type: null,
      menuActive: 1,
      header: {},
      menus: [],
      loadingListPersonalData: false,
      loadingDetailPersonalData: false,
      idKelas: this.$route.params.id_kelas,
      isMetadata: false,
      arrayReport: [],
      kumer: false,
    }
  },
  methods: {
    verifyNow() {
      const data = this.dataReport
      this.$confirm({
        title: 'Verify Report',
        content: (
          <div>
            <div>
              Are you sure to verify {data.murid.nama}'s {this.type} report ?
            </div>
          </div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('homeroom/VERIFY_REPORT', {
              id: data.id,
              by: 'principal',
            })
            this.$notification.success({
              message: 'Success Verify.',
              description: `${data.murid.nama}'s ${this.type} report is successfully verified.`,
            })
            this.fetchPersonalData()
          } catch (e) {
            console.log(e)
            this.$notification.error({
              message: 'Error',
              description: `Verifying ${data.murid.nama}'s ${this.type} report is failed. Please try again.`,
            })
          }
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Verify',
      })
    },
    backToMenu() {
      this.$router.push({ name: 'Curriculum Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Currriculum Teacher'],
      })
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    async fetchHeader(query = '') {
      try {
        const res = await this.$store.dispatch(
          'homeroom/FETCH_CURRICULUM_HEADER_PERSONAL_DATA',
          { idKelas: this.idKelas },
        )
        this.header = res
        this.menus = res.murid.map((el, i) => {
          return {
            key: i + 1,
            menu: `${el.murid.nama}`,
            ...el,
          }
        })
        this.fetchPersonalData()
      } catch (err) {
        console.log(err)
      }
    },
    async fetchPersonalData() {
      try {
        this.loadingListPersonalData = true
        this.loadingDetailPersonalData = true
        const res = await this.$store.dispatch(
          'homeroom/FETCH_CURRICULUM_STUDENT_REPORT',
          { idKelas: this.idKelas, type: this.type },
        )
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        if (!res.arrayMetadata) {
          const { message, description } = res
          return this.$notification.error({
            message,
            description,
          })
        }
        console.log(res, 'inikumerlooo')
        this.kumer = res.kumer
        this.arrayReport = res.arrayMetadata
        this.isMetadata = res.isMetadata
        this.menuActive = 1
        // let no = 1
        // this.menus = res.map(el => {
        //   return {
        //     key: no++,
        //     menu: `${el.nama}`,
        //     ...el,
        //   }
        // })
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    if (
      this.$route.query.type === 'mid' ||
      this.$route.query.type === 'final'
    ) {
      this.type = this.$route.query.type
      this.fetchHeader()
    } else {
      this.$router.push({ name: 'Curriculum Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Currriculum Teacher'],
      })
    }

    window.addEventListener('keyup', (event) => {
      event.preventDefault()
      if (this.menus.length) {
        if (event.keyCode === 40 && this.menuActive !== this.menus.length) {
          this.menuActive = this.menuActive + 1
        } else if (event.keyCode === 38 && this.menuActive !== 1) {
          this.menuActive = this.menuActive - 1
        }
      }
    })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    dataReport() {
      for (let i = 0; i < this.menus.length; i++) {
        const menu = this.menus[i]
        if (menu.key === this.menuActive) {
          const dataReport = this.arrayReport.find(
            (report) => report.murid.nis === menu.murid.nis,
          )
          if (dataReport) {
            return {
              key: menu.key,
              ...dataReport,
            }
          }
        }
      }
      return {}
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    isPrincipal() {
      return this.$store.state.user.role.includes('kepalaSekolah')
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 600px;
  overflow-y: auto;
}
</style>
